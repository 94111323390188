import { CloseIcon, SearchIcon } from "@chakra-ui/icons"
import {
	Box,
	Input,
	InputGroup,
	InputLeftElement,
	InputRightElement,
	Link,
	Spinner,
	Text,
} from "@chakra-ui/react"
import { useSearchContext } from "@common/components/SearchContextProvider"
import NextLink from "next/link"
import { MouseEventHandler, useMemo } from "react"
import { navSectionHeaders } from "./docs/NavBar"
import { Post } from "./types/Post"

export const MIN_SEARCH_CHAR_LENGTH = 1

interface SearchResultProps {
	posts: Post[]
	onClick: MouseEventHandler<HTMLDivElement>
}

// key for group which has results but not part of navigation bar
const GENERAL_RESULTS_KEY = "GENERAL"

const SearchResults = ({ posts, onClick }: SearchResultProps) => {
	const groupedResults: Record<string, Post[]> = useMemo(() => {
		const results: Record<string, Post[]> = {}
		navSectionHeaders.forEach((section) => {
			results[section.value] = []
		})
		results[GENERAL_RESULTS_KEY] = []

		posts?.forEach((post) => {
			if (results[post.documentationSection]) {
				results[post.documentationSection].push(post)
			} else {
				results[GENERAL_RESULTS_KEY].push(post)
			}
		})

		const sortedResults: Record<string, Post[]> = {}
		Object.keys(results)
			.sort()
			.forEach((key) => {
				if (key !== GENERAL_RESULTS_KEY) {
					sortedResults[key] = results[key]
				}
			})
		sortedResults[GENERAL_RESULTS_KEY] = results[GENERAL_RESULTS_KEY]

		return sortedResults
	}, [posts])

	const isNotEmpty = Object.keys(groupedResults).some(
		(key) => groupedResults[key].length > 0
	)

	return (
		<Box
			position="absolute"
			top={0}
			left={0}
			zIndex={10}
			background="#FFF"
			boxShadow="2xl"
			ml="16px"
			p="16px"
			border="1px solid"
		>
			{isNotEmpty ? (
				Object.entries(groupedResults).map(([key, posts]) => {
					const title =
						navSectionHeaders
							.find((section) => section.value === key)
							?.title.toUpperCase() ?? GENERAL_RESULTS_KEY
					return (
						<Box key={key} display={posts.length ? "inherit" : "none"} mb={4}>
							<Box
								fontSize={12}
								fontWeight={700}
								pb={2}
								mb={2}
								borderBottom={"1px solid"}
							>
								{title}
							</Box>
							{posts.map((post) => {
								return (
									<Box key={post.slug} onClick={onClick}>
										<NextLink
											href={`/docs/${post.slug}`}
											passHref
											legacyBehavior
										>
											<Link>{post.title}</Link>
										</NextLink>
									</Box>
								)
							})}
						</Box>
					)
				})
			) : (
				<Text>No matching results.</Text>
			)}
		</Box>
	)
}

interface SearchProps {
	borderColor: string
	onChange(event: React.ChangeEvent<HTMLInputElement>): void
	displaySearchResults?: boolean
	maxWidth?: string
}

export const SearchBar = ({
	onChange,
	borderColor,
	displaySearchResults = false,
	maxWidth
}: SearchProps) => {
	const {
		state: { isSearching, searchTerms, searchResults },
		actions: { setSearchTerms },
	} = useSearchContext()

	const sortedResults = useMemo(() => {
		return searchResults.sort((a: Post, b: Post) =>
			a?.title.localeCompare(b?.title)
		)
	}, [searchResults])

	const isSearchingStyle = {
		position: "absolute",
		top: "12px",
		right: searchTerms.length > 0 ? "36px" : "18px",
		size: "sm",
	}

	return (
		<Box width="100%" maxWidth={maxWidth} px={1}>
			<InputGroup
				borderRadius={5}
				size="sm"
				className="search-bar"
				alignItems="center"
				position="relative"
			>
				<InputLeftElement pointerEvents="none" top="4px" left="12px">
					<SearchIcon />
				</InputLeftElement>
				{isSearching && searchTerms[0]?.length >= MIN_SEARCH_CHAR_LENGTH && (
					<Spinner sx={isSearchingStyle} />
				)}
				{searchTerms.length > 0 && (
					<InputRightElement
						fontSize="10px"
						position="absolute"
						top="4px"
						right="6px"
						cursor="pointer"
						onClick={() => setSearchTerms([])}
					>
						<CloseIcon />
					</InputRightElement>
				)}
				<Input
					height="40px"
					type="text"
					value={searchTerms?.join(" ")}
					borderRadius={20}
					borderColor={borderColor}
					boxShadow="lg" 
					pl={12}
					placeholder="Search..."
					_placeholder={{ opacity: 1.0, color: "#9B9595" }}
					onChange={onChange}
				/>
			</InputGroup>
			{displaySearchResults && (
				<Box position="relative">
					{!isSearching && searchTerms[0]?.length >= MIN_SEARCH_CHAR_LENGTH && (
						<SearchResults posts={sortedResults} onClick={() => setSearchTerms([])} />
					)}
				</Box>
			)}
		</Box>
	)
}
