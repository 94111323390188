import {
	Box,
	Flex,
	HStack,
	Link,
	Text,
	Tooltip,
	VStack,
} from "@chakra-ui/react"
import VideoElement from "@common/components/VideoElement"
import { PortableText } from "@portabletext/react"
import Image from "next/image"
import SyntaxHighlighter from "react-syntax-highlighter"
import { agate } from "react-syntax-highlighter/dist/cjs/styles/hljs"
import { SanityCategoryTypes } from "../constants/cmsConstants"
import { SanityTag } from "./types/Post"
import { PortableTextComponents } from '@portabletext/react'
import React from "react"

// CSS styling for image assets
export const captionStyle = {
	color: "#999",
	fontSize: "14px",
	lineHeight: "20px",
	margin: "-8px 0px 24px",
	textAlign: "center" as const,
}

// one of these icons will be displayed above the post title
const tagIcons = {
	// Blog
	articles: "📰",
	company: "🏢",
	education: "🎓",
	industry: "🏭",
	network_visiblity: "👁️",
	news: "📰",
	performance: "🚀",
	releases: "⭐️",
	security: "🛡️",
	threat_intel: "🎯",

	// Release Notes
	bug_fixes: "🪲",
	new_features: "⭐️",
	performance_updates: "🚀",

	// Docs
	general: "📚",
	guide: "📒",
	how_to: "🎓",
}

// maximum number of tags to display within thumbnail
const MAX_DISPLAY_TAGS = 5

/**
 * Returns a React component containing the given tags
 * @param component the component we are displaying
 * @param tags any other type of tag
 * @param componentTags the application-specific tags
 * @returns a React component
 */
export const getTags = (
	component: SanityCategoryTypes,
	tags: SanityTag[],
	componentTags: SanityTag[]
) => {
	const sortedTags = tags
		? tags.sort((a, b) => a.label.localeCompare(b.label))
		: []
	const sortedComponentTags = componentTags
		? componentTags.sort((a, b) => a.label.localeCompare(b.label))
		: []

	const displayTags = sortedTags.concat(sortedComponentTags)

	const TagOverflow = (
		<VStack alignItems="flex-start" className={`tags tags-${component}`}>
			{displayTags?.slice(MAX_DISPLAY_TAGS).map((tag) => (
				<Flex
					key={tag.label}
					direction="row"
					gap="4px"
					justifyContent={"space-between"}
					alignItems="center"
					className="tag"
				>
					{tagIcons[tag.value] && <Text>{tagIcons[tag.value]}</Text>}
					<Text key={tag.label} fontSize="14px" fontWeight="normal">
						{tag.label}
					</Text>
				</Flex>
			))}
		</VStack>
	)

	return (
		<HStack className={`tags tags-${component}`} alignItems="center" flexWrap="wrap" rowGap="6px">
			{displayTags?.slice(0, MAX_DISPLAY_TAGS).map((tag) => {
				return (
					<Flex
						key={tag.label}
						direction="row"
						gap="4px"
						justifyContent={"space-between"}
						alignItems="center"
						className="tag"
					>
						{tagIcons[tag.value] && <Text>{tagIcons[tag.value]}</Text>}
						<Text sx={{ textWrap: "nowrap" }}>{tag.label}</Text>
					</Flex>
				)
			})}
			{displayTags.length > MAX_DISPLAY_TAGS && (
				<Tooltip label={TagOverflow} className="tags-tooltip">
					&nbsp;...&nbsp;
				</Tooltip>
			)}
		</HStack>
	)
}

// custom renderer for Sanity code component
const Code = ({ value }) => {
	return (
		<Box className="sanity-code">
			<SyntaxHighlighter language={value.language} style={agate}>
				{value.code}
			</SyntaxHighlighter>
		</Box>
	)
}

const bannerColors = {
	blue: {
		bg: "rgb(236, 246, 253)",
		border: "rgb(16, 144, 224)",
	},
	gray: {
		bg: "rgb(238, 239, 240)",
		border: "rgb(48, 53, 64)",
	},
	green: {
		bg: "rgb(235, 245, 240)",
		border: "rgb(0, 136, 68)",
	},
	red: {
		bg: "rgb(252, 239, 240)",
		border: "rgb(211, 61, 68)",
	},
	yellow: {
		bg: "rgb(254, 249, 235)",
		border: "rgb(248, 174, 0)",
	},
	default: {
		bg: "rgb(236, 246, 253)",
		border: "rgb(16, 144, 224)",
	},
}

interface BannerProps {
	value: {
		color?: string
		content: any[]
	}
}

// custom renderer for Sanity banner component
const Banner = ({ value }: BannerProps) => {
	const colors = bannerColors[value.color] ?? bannerColors.default

	return (
		<Box
			className="sanity-banner"
			background={colors.bg}
			borderColor={colors.border}
			color="theme_text_dark"
			borderLeftWidth="4px"
			padding="11px 12px 11px 26px"
			margin="16px"
		>
			<Box className={"content"}>
				<PortableText value={value.content} />
			</Box>
		</Box>
	)
}

// custom renderer for Sanity table component
const SanityTable = ({ value }) => {
	return (
		<table className="sanity-table">
			<tbody>
				{value.rows?.map((row, rowIndex) => (
					<tr key={rowIndex} className="row">
						{row.cells?.map((cell, colIndex) =>
							rowIndex === 0 ? (
								<th key={colIndex} className="cell">
									{cell}
								</th>
							) : (
								<td key={colIndex} className="cell">
									{cell}
								</td>
							)
						)}
					</tr>
				))}
			</tbody>
		</table>
	)
}

const orderedListRegExp = /(^[0-9]+\.)/
const anchorStyle = { visibility: "hidden", position: "absolute", top: "-72px", left: 0 } as React.CSSProperties

// how to render Sanity components within <PortableText />
export const portableComponents: PortableTextComponents = {
	block: {
		h2: ({ children }) => {
			return (
			<Box position={"relative"}>
				<a id={`${children[0]}`} style={anchorStyle} />
				<h2>{children}</h2>
			</Box>
			)
		},
		h3: ({ children }) => {
			return (
				<Box position={"relative"}>
					<a id={`${children[0]}`} style={anchorStyle} />
					<h3>{children}</h3>
				</Box>
				)
		},
		h4: ({ children }) => {
			return (
				<Box position={"relative"}>
					<a id={`${children[0]}`} style={anchorStyle} />
					<h4>{children}</h4>
				</Box>
				)
		},
		normal: ({ children }) => {
			// handle indentation for manually made ordered lists
			if (orderedListRegExp.test(children[0])) {
				if(Array.isArray(children)) {
					const split = children[0].split(orderedListRegExp)
					const itemNumber = <span className="number-icon">{split[1]?.replace(".", "")}</span>
					const itemText = <span>{split[2]}</span>
					const slice = children.slice(1)
					return <Text className="numbered-text" paddingLeft="14px">{itemNumber} {itemText} {slice}</Text>
				}
			}
			return <Text>{children}</Text>
		},
	},
	types: {
		table: SanityTable,
		code: Code,
		banner: Banner,
		image: ({ value }) => (
			<div className="sanity-image">
				<Image
					src={value.asset.url}
					alt={value.alt ?? "image"}
					width={value.asset.metadata.dimensions.width}
					height={value.asset.metadata.dimensions.height}
					style={{ margin: "24px auto 16px auto" }}
				/>
			</div>
		),
		imageAsset: ({ value }) => (
			<div className="sanity-imageAsset">
				<Image
					src={value.asset.url}
					alt={value.alt ?? "image"}
					width={value.asset.metadata.dimensions.width}
					height={value.asset.metadata.dimensions.height}
					style={{ margin: "24px auto 16px auto" }}
				/>
				{value.caption && (
					<div style={{ ...captionStyle }}>{value.caption}</div>
				)}
			</div>
		),
		videoAsset: ({ value }) => (
			<div className="sanity-videoAsset">
				<VideoElement
					videoUrl={value.url}
					width="100%"
					height="100%"
					loop={false}
					playing={false}
					muted={false}
					controls={true}
				/>
			</div>
		),
	},
	marks: {
		internalLink: ({ children, value }) => {
			return (
				<Link
					href={`/docs/${value.slug.current}`}
					textDecoration="underline"
				>
					{children}
				</Link>
			)
		},
	},
}
