import {
	Box,
	Center,
	HStack,
	Link,
	Spinner,
	Text,
	VStack,
} from "@chakra-ui/react"
import { SanityCategoryTypes } from "@cms/constants/cmsConstants"
import { getAllPostsByCategory } from "@cms/utils/sanityApi"
import { useSearchContext } from "@common/components/SearchContextProvider"
import { useEffect, useState } from "react"
import { BiSolidBook } from "react-icons/bi"
import { BsMouse2Fill } from "react-icons/bs"
import {
	IoIosArrowForward,
	IoIosRocket,
	IoMdHelpCircle,
	IoMdPerson,
} from "react-icons/io"

import NextLink from "next/link"
import { SearchBar } from "../SearchBar"
import { Post } from "../types/Post"

export const linkUnderlineBeforeStyle = {
	content: '""',
	position: "absolute",
	display: "block",
	width: "100%",
	height: "1.5px",
	bottom: "-3px",
	left: 0,
	backgroundColor: "#0E0E0E",
	transform: "scaleX(0)",
	transition: "opacity 0.3s ease, transform 0.3s ease",
}

export const linkStyle = {
	fontWeight: "500",
	textAlign: "left",
	position: "relative",
	textDecoration: "none",

	_before: {
		...linkUnderlineBeforeStyle,
	},
}

export const activeLinkStyle = {
	color: "#2F6DAB",
}

interface ISection {
	title: string
	description: string
	value: string // this value directly corresponds to Sanity CMS "tag"
	icon: React.ReactElement
}

// order of this array is the order displayed within the navigation bar
export const navSectionHeaders: ISection[] = [
	{
		title: "Getting Started",
		value: "getting_started",
		icon: <IoIosRocket />,
		description:
			"Click on one of the documents below to learn more about Teleseer.",
	},
	{
		title: "Account",
		value: "account",
		icon: <IoMdPerson />,
		description: "Learn how to manage your Teleseer account.",
	},
	{
		title: "Using Teleseer",
		value: "using_teleseer",
		icon: <BsMouse2Fill />,
		description:
			"Explore the application by clicking on one of the documents below.",
	},
	{
		title: "Tutorials",
		value: "tutorials",
		icon: <BiSolidBook />,
		description:
			"Looking to do something specific within Teleseer? Click on one of the documents below.",
	},
	{
		title: "Help",
		value: "help",
		icon: <IoMdHelpCircle />,
		description:
			"Get help quickly and find out answers to frequently asked questions.",
	},
]

interface Props {
	currentPost: Post
	onSearchTermChange(event: React.ChangeEvent<HTMLInputElement>): void
	onDocSectionClick(section: string): void
	searchBarMaxWidth?: string
}

export const NavBar = ({
	currentPost,
	onSearchTermChange,
	onDocSectionClick,
	searchBarMaxWidth
}: Props) => {
	const [isLoadingPosts, setIsLoadingPosts] = useState<boolean>(true)
	const [posts, setPosts] = useState<Post[]>([])
	const {
		state: { activeDocSection },
	} = useSearchContext()

	useEffect(() => {
		setIsLoadingPosts(true)
		getAllPostsByCategory(SanityCategoryTypes.Support).then((posts) => {
			setIsLoadingPosts(false)
			setPosts(posts)
		})
	}, [setIsLoadingPosts])

	return (
		<VStack alignItems={"flex-start"} minWidth="300px">
			<SearchBar
				onChange={onSearchTermChange}
				borderColor="search_border_dark"
				displaySearchResults={true}
				maxWidth={searchBarMaxWidth}
			/>
			<VStack alignItems="flex-start" pt={4} spacing={4}>
				{/* Section */}
				{navSectionHeaders.map((section) => (
					<Box key={section.value} width="100%">
						{/* Section Header */}
						<Link
							cursor={"pointer"}
							onClick={() => onDocSectionClick(section.value)}
							{...(activeDocSection === section.value &&
								!currentPost &&
								activeLinkStyle)}
						>
							<HStack justifyContent={"space-between"}>
								<HStack ml={"8px"}>
									{section.icon}
									<Text fontSize="16px" fontWeight="700" p="8px 0">
										{section.title.toUpperCase()}
									</Text>
								</HStack>
								{activeDocSection === section.value && !currentPost && (
									<IoIosArrowForward />
								)}
							</HStack>
						</Link>
						{/* Section Links */}
						<VStack alignItems="flex-start" style={{ marginTop: "8px" }}>
							{isLoadingPosts ? (
								<Center width="100%" padding={2}>
									<Spinner />
								</Center>
							) : (
								posts.map((post) => {
									if (post.documentationSection === section.value) {
										return (
											<NextLink
												href={`/docs/${post.slug}`}
												key={post.slug}
												passHref
												legacyBehavior
											>
												<Link
													fontSize="xs"
													style={{
														marginLeft: 8,
													}}
													sx={{
														...linkStyle,
														...(currentPost?.slug === post.slug &&
															activeLinkStyle),
													}}
												>
													{post.title}
												</Link>
											</NextLink>
										)
									}
								})
							)}
						</VStack>
					</Box>
				))}
			</VStack>
		</VStack>
	)
}
